<template>
  <a-modal
    :confirm-loading="loading"
    title="创建告警分类"
    :visible="visible"
    :width="720"
    @ok="ok"
    @cancel="reset"
  >
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="分类名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入告警分类名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" :max-length="128" placeholder="请输入告警分类描述"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createAlertCategory } from '@/api/alert-category'

export default {
  name: 'CreateForm',
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        name: '',
        description: ''
      },
      rules: {
        name: [{ message: '请输入告警分类名称', required: true, trigger: 'blur' }]
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createAlertCategory(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
